<template>
  <div class="guide">
    <Normaltop
      :url="qiniuUrl"
      title="证书发放"
      en="Certificate issuance"
    ></Normaltop>
    <div class="guide-cont">
      <div class="part">
        <h2 class="part-title">证书查询</h2>
        <div style="max-width:500px">
          <el-row :gutter="20" class="flex-col-center part-cont">
            <el-col :span="8">
              <span>证书持有人姓名:</span>
            </el-col>
            <el-col :span="16">
              <el-input
                type="text"
                placeholder="请输入证书持有人姓名"
                v-model="search.name"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20" class="flex-col-center part-cont">
            <el-col :span="8">
              <span>手机号或身份证号:</span>
            </el-col>
            <el-col :span="16">
              <el-input
                type="text"
                placeholder="请输入持有人手机号或身份证号"
                v-model="search.search"
              />
            </el-col>
          </el-row>
        </div>
        <p class="part-cont searchBtn">
          <el-button type="danger" @click="searchCert">查询</el-button>
        </p>

        <div v-show="notCertShow" style="margin-left:140px">
          <img class="no" src="../assets/images/certs/no.png" alt srcset />
          <p class="part-cont ">未查询到证书</p>
        </div>
        <el-table
          v-show="tableShow"
          :data="tableData"
          border
          style="width: 70%"
        >
          <el-table-column label="序号" width="80px">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="证书名称">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 0">初级网培师证书</span>
              <span v-if="scope.row.type == 1">中级网培师证书</span>
            </template>
          </el-table-column>

          <el-table-column prop="pay_type" label="查看">
            <template slot-scope="scope">
              <el-button type="text" @click="certShow(scope.row.cert)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :visible.sync="certImgShow" width="600px">
      <img :src="url + imgUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop'
export default {
  name: 'CertSearch',
  components: { Normaltop },
  data() {
    return {
      qiniuUrl: this.$qiniuUrl + 'certs/banner.jpg',
      search: {
        search: '',
        name: '',
      },
      tableShow: false,
      notCertShow: false,
      tableData: [],
      certImgShow: false,
      url: 'http://api.wangpeishi.org.cn/',
      imgUrl: '',
    }
  },
  created() {},
  methods: {
    searchCert() {
      let _this = this
      if (_this.search.name == '') {
        _this.$message.error('请输入证书持有人姓名')
        return false
      }
      if (_this.search.search == '') {
        _this.$message.error('请输入持有人手机号或身份证号')
        return false
      }
      _this.$http
        .post(process.env.VUE_APP_URL + 'cert', _this.search)
        .then(data => {
          if (data.data.code == 200) {
            if (data.data.data.length == 0) {
              _this.notCertShow = true
              _this.tableShow = false
            } else {
              _this.tableData = data.data.data
              _this.tableShow = true
              _this.notCertShow = false
            }
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    certShow(row) {
      this.certImgShow = true
      this.imgUrl = row
    },
  },
}
</script>
<style lang="less" scoped>
@common-red: #e10133;

.guide {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }

  .part {
    width: 60%;
    min-width: 1100px;
    margin: 0 auto;
    position: relative;
    padding: 50px 0;
    .methods {
      padding: 25px 0;
    }
    .part-title {
      color: #000005;
      font-weight: bold;
      font-size: 36px;
      padding-bottom: 50px;
    }
    .part-h3 {
      font-size: 30px;
    }
    .el-input {
      width: 350px;
    }
    .part-cont {
      padding-bottom: 30px;
      color: #333;
      font-size: 18px;
    }

    .no {
      width: 80px;
      padding-left: 15px;
    }
    /deep/.el-button--danger {
      background: @common-red;
      border-color: @common-red;
      width: 200px;
    }
  }
}
input {
  width: 300px;
  height: 30px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
}
input::-webkit-input-placeholder {
  color: #808080;
  font-size: 13px;
  padding-left: 5px;
  letter-spacing: 1px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #808080;
  font-size: 13px;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #808080;
  font-size: 13px;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #808080;
  font-size: 13px;
}
.searchBtn {
  padding-left: 95px;
}
</style>
